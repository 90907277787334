/** @jsx jsx */
import { jsx } from "theme-ui"
import ProjectsListItem from "./projects-list-item"

type ListingProps = {
  projects: {
    slug: string
    title: string
    icon: string
    excerpt: string
  }[]
  className?: string
}

const ProjectsListing = ({ projects, className = `` }: ListingProps) => {
  return(
    <section sx={{ mb: [5, 5, 6] }} className={className}>
      {projects.map((project) => (
        <ProjectsListItem key={project.slug} project={project} />
      ))}
    </section>
  )
}

export default ProjectsListing
