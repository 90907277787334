import { graphql } from "gatsby"
import ProjectsPageComponent from "../@lekoarts/gatsby-theme-minimal-blog/components/projects-page"

export default ProjectsPageComponent

export const query = graphql`
  query {
    allProject {
      nodes {
        slug
        title
        icon
        excerpt
      }
    }
  }
`
